<template>
  <MainCard>
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="tabItem in tabs"
          :key="tabItem.title"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <attendance-student></attendance-student>
        </v-tab-item>
        <v-tab-item>
          <attendance-employee></attendance-employee>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import AttendanceEmployee from './AttendanceEmployee.vue'
import AttendanceStudent from './AttendanceStudent.vue'

export default {
  name: 'Attendance',
  components: {
    AttendanceStudent,
    AttendanceEmployee,
    MainCard,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Absensi Siswa' }, { title: 'Absensi Pegawai' }],
    }
  },
}
</script>

<style>
</style>
