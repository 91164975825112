<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :search="search"
          :items="attendances"
          :page="page"
          :no-add-button="noAddButton"
          :print-recap-button="printRecapButton"
          :filter-employee-attendance="filterEmployeeAttendance"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Absensi Pegawai"
          subtitle="Halaman untuk absensi pegawai "
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @filter-from-date-atd="handleFromDateAtd"
          @filter-to-date-atd="handleToDateAtd"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'AttendanceStudentHeadMaster',
  components: {
    DataTablePagination,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      dialog: '',
      search: '',
      class: '',
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      printRecapButton: true,
      // printRecap: 'http://192.168.110.127:8001/api/v1/export-attendance-all',
      noAddButton: true,
      filterClass: true,
      filterEmployeeAttendance: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      service: 'attendanceemployee',
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Lengkap', value: 'name' },
        { text: 'Status', value: 'isActive' },
        { text: 'Hadir (H)', value: 'hadir' },
        { text: 'Alfa (A)', value: 'alpha' },
        { text: 'Sakit (S)', value: 'sakit' },
        { text: 'Izin (I)', value: 'izin' },
        { text: 'Aksi', value: 'attendanceActions' },
      ],
      students: [],
      attendances: [],
      school: '',
      school_uuid: '',
      user_uuid: '',
      from_date_atd: '',
      to_date_atd: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listAttendanceEmployee()
      },
    },
    search: {
      handler() {
        this.listAttendanceEmployee({ search: this.search })
      },
    },
    class: {
      handler() {
        this.listAttendanceEmployee({ class_uuid: this.class })
      },
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.listAttendanceEmployee()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async listAttendanceEmployee(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
        start_date: this.from_date_atd,
        end_date: this.to_date_atd,
      }).then(
        ({ data }) => {
          this.attendances = data.data.map((attendances, index) => ({
            ...attendances,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(search) {
      this.search = search
      this.listAttendanceEmployee({ search })
    },

    handleFromDateAtd(date) {
      this.from_date_atd = date
      this.listAttendanceEmployee({ start_date: date })
    },

    handleToDateAtd(date) {
      this.to_date_atd = date
      this.listAttendanceEmployee({ end_date: date })
    },
  },
}
</script>

<style>
</style>
